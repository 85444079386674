import React, { useState } from "react";
import Nav from "../components/Nav";
import { useParams, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import { iconImages } from "../utils/images";

const UpdateDetails = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ email });
  };

  return (
    <>
      <Nav />
      <div className="flex flex-col gap-4 px-5 md:px-20 lg:px-40 mt-24">
        <h2 className="text-2xl font-bold text-[#292930]">{state.title}</h2>
        <div className="w-full md:h-[300px] w-ful overflow-hidden mx-auto">
          <img src={state.icon} alt="" className="w-full h-auto object-cover" />
        </div>

        <div className="border-b border-[#CCCCCC] flex items-center justify-between pb-3">
          <div className="flex items-center gap-2">
            <img src={iconImages.Poster} alt="" className="rounded-full w-12" />
            <div className="flex flex-col">
              <p className="text-[#292930] font-semibold text-sm">Onaivi</p>
              <p className="text-[#555555] font-[400] text-sm">April 1, 2024</p>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <img src={iconImages.Inst} alt="" className="w-5" />
            <img src={iconImages.Fb} alt="" className="w-5" />
            <img src={iconImages.Tweet} alt="" className="w-5" />
            <img src={iconImages.Link} alt="" className="w-5" />
          </div>
        </div>

        <div className="flex flex-col gap-3 font-[inter] font-[400] text-xs md:text-sm text-[#003C1C]">
          <p>
            In July 1989, the Africa Union, then called Organization of African
            Unity (O.A.U.) proclaimed 20th November as Africa Industrialization
            Day. “Industrialization, with strong linkages to domestic economies,
            will help African countries achieve high growth rates, diversify
            their economies and reduce their exposure to external shocks.”
          </p>

          <p>
            It is essential for African countries to pursue a balanced and
            inclusive approach to industrialization and agriculture, considering
            the specific needs and potentials of each sector and region. One
            promising strategy is to promote agro-industrialization, which is
            the integration of agriculture and industry through the development
            of agro-processing, agro-services, and agro-based value chains.
            Agro-industrialization can help transform Africa’s agriculture from
            subsistence to commercial, adding value to agricultural products,
            increasing productivity and competitiveness, and creating linkages
            with other sectors of the economy.
          </p>
          <p>
            Technology has played a significant role in the industrialization of
            the agricultural sector in Africa. Digital technologies have enabled
            farmers to access information, services, and products that improve
            efficiency, boost crop yields, and increase incomes. For instance,
            apps like Hello Tractor and DigiFarm, ThriveAgric and Ajéoba Agro
            Exchange have made it easier for smallholder farmers to access
            better machinery. Some of these apps also connect farmers with
            tractor owners, weather alerts, and resource sellers.
          </p>

          <p>
            This has helped the agricultural sector in Africa by providing
            farmers with access to information, services, and products that
            improve efficiency, boost crop yields, and increase incomes.
          </p>

          <p>
            Agro-industrialization is a promising strategy to achieve this goal,
            as it can help transform Africa’s agriculture into a dynamic and
            competitive sector, while also supporting industrial development and
            diversification. By doing so, Africa can achieve its vision of a
            prosperous and integrated continent, as articulated in the Agenda
            2063.
          </p>
        </div>

        <div className="border-y flex items-center justify-between py-3 w-[80%] mx-auto my-5 md:my-10">
          <p className="text-[#1B2534] font-semibold text-sm">Share</p>

          <div className="flex items-center gap-2">
            <img src={iconImages.Inst} alt="" className="w-5" />
            <img src={iconImages.Fb} alt="" className="w-5" />
            <img src={iconImages.Tweet} alt="" className="w-5" />
            <img src={iconImages.Link} alt="" className="w-5" />
          </div>
        </div>
      </div>
      <div className="flex flex-col py-32 md:py-24 bg-[#E7F1ED] relative overflow-y-hidden">
        <img
          src={iconImages.Radial}
          alt=""
          className="absolute w-12 left-5 md:left-[29%] top-5 md:top-10"
        />

        <img
          src={iconImages.Ring}
          alt=""
          className="absolute w-5 right-[50%] md:right-[25%] bottom-2 md:top-16"
        />

        <img
          src={iconImages.Bow}
          alt=""
          className="absolute w-16 md:w-12 right-[15%] top-7 md:top-5"
        />

        <img
          src={iconImages.Mega}
          alt=""
          className="absolute w-16 bottom-3 md:bottom-10 left-5 md:left-[20%]"
        />

        <img
          src={iconImages.Fullstar}
          alt=""
          className="absolute w-3 bottom-[260px] md:bottom-[50px] left-[15%] md:left-[35%]"
        />

        <img
          src={iconImages.Rocket}
          alt=""
          className="absolute w-10 bottom-3 md:bottom-3 right-[15%] md:right-[28%]"
        />

        <div className="absolute md:top-0 right-[50%] translate-x-[50%]">
          <img src={iconImages.Pattern} alt="" className="w-[550px]" />
        </div>
        <div className="mx-auto flex flex-col items-center gap-2 z-10">
          <p className="font-bold text-xl md:text-3xl ">
            <span className="text-[#003C1C]">Subscribe to our</span>{" "}
            <span className="text-[#006D33]">newsletter</span>
          </p>

          <div className="w-full md:w-[95%]">
            <form
              onSubmit={handleSubmit}
              className="flex md:flex-row flex-col items-center gap-2 z-[100]"
            >
              <input
                type="email"
                placeholder="Enter Your Email..."
                className="px-5 py-2 rounded-md bg-[#F0F5EC] text-[#003C1C] focus:outline-none placeholder:text-[#003C1C] placeholder:text-sm w-full"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="w-full md:w-fit px-5 py-[10px] bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-md text-sm md:text-base">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UpdateDetails;
