import React, { useState } from "react";
import { iconImages } from "../utils/images";
import ClientItems from "./ClientItems";

const Clients = () => {
  const [startIndex, setStartIndex] = useState(0);
  const clients = [
    // Your client items data here
    // use an actual sample data here so that the images and text will be differnet
    {
      id: 1,
      name: "Timothy Simmons",
      title: "TenedInc, CEO",
      icon: iconImages.Timo,
    },

    {
      id: 2,
      name: "Mayowa S. Wilson",
      title: "Zibarr.ltd, CEO",
      icon: iconImages.Mayowa,
    },

    {
      id: 3,
      name: "Timothy Simmons",
      title: "TenedInc, CEO",
      icon: iconImages.Timo,
    },

    {
      id: 4,
      name: "Mayowa S. Wilson",
      title: "Zibarr.ltd, CEO",
      icon: iconImages.Mayowa,
    },

    {
      id: 5,
      name: "Timothy Simmons",
      title: "TenedInc, CEO",
      icon: iconImages.Timo,
    },

    {
      id: 6,
      name: "Mayowa S. Wilson",
      title: "Zibarr.ltd, CEO",
      icon: iconImages.Mayowa,
    },

    {
      id: 7,
      name: "Timothy Simmons",
      title: "TenedInc, CEO",
      icon: iconImages.Timo,
    },

    {
      id: 8,
      name: "Mayowa S. Wilson",
      title: "Zibarr.ltd, CEO",
      icon: iconImages.Mayowa,
    },

    {
      id: 9,
      name: "Timothy Simmons",
      title: "TenedInc, CEO",
      icon: iconImages.Timo,
    },
  ];

  const handlePrev = () => {
    setStartIndex(Math.max(startIndex - 1, 0));
  };

  const handleNext = () => {
    setStartIndex(Math.min(startIndex + 1, clients.length - 2));
  };

  const mobilePrev = () => {
    setStartIndex(Math.max(startIndex - 1, 0));
  };

  const mobileNext = () => {
    setStartIndex(Math.min(startIndex + 1, clients.length - 2));
  };

  return (
    <div className="my-10 flex flex-col gap-8">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-4 lg:w-[600px]">
          <h3 className="text-[#130F26] font-bold text-2xl lg:text-4xl md:text-3xl lg:w-[75%] lg:text-left text-left lg:leading-[55px]">
            What say happy clients
          </h3>
          <p className="font-[inter] font-[300] lg:text-base lg:text-left text-xs md:text-sm">
            At our marketplace, we're not just selling farm products and inputs;
            we're 2B/B2C digital storefront for quality agricultural produce and
            inputs.
          </p>
        </div>
        <div className="lg:flex items-center gap-5 hidden">
          <div
            className="w-10 h-10 rounded-full flex items-center justify-center bg-[#38DA711A] cursor-pointer"
            onClick={handlePrev}
          >
            <img src={iconImages.Prev} alt="Previous" />
          </div>
          <div
            className="w-10 h-10 rounded-full flex items-center justify-center bg-[#006D33] cursor-pointer"
            onClick={handleNext}
          >
            <img src={iconImages.Next} alt="Next" />
          </div>
        </div>
      </div>
      <div className="overflow-x-hidden">
        <div className="hidden lg:flex flex-row md:hidden items-center gap-4 transition-transform duration-500 ease-in-out">
          {clients.slice(startIndex, startIndex + 2).map((item, index) => (
            <ClientItems
              key={index}
              note={`${item.id} Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit semper consequat in dolor mattis pellentesque suspendisse vulputate nec.Scelerisque elementum, consequat pharetra lectus eget vivamus ut. Magnaaliquam tellus at volutpat cras aliquet facilisi quis adipiscing. Proinegestas et pellentesque augue pretium, amet. Natoque blandit augueinteger maecenas mattis amet, nec.`}
              name={item.name}
              image={item.icon}
              title={item.title}
            />
          ))}
        </div>

        <div className="flex lg:hidden md:flex flex-row items-center gap-4 transition-transform duration-500 ease-in-out">
          {clients.slice(startIndex, startIndex + 1).map((item, index) => (
            <ClientItems
              key={index}
              note={`${item.id} Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit semper consequat in dolor mattis pellentesque suspendisse vulputate nec.Scelerisque elementum, consequat pharetra lectus eget vivamus ut. Magnaaliquam tellus at volutpat cras aliquet facilisi quis adipiscing. Proinegestas et pellentesque augue pretium, amet. Natoque blandit augueinteger maecenas mattis amet, nec.`}
              name={item.name}
              image={item.icon}
              title={item.title}
            />
          ))}
        </div>
      </div>
      <div className="lg:hidden items-center gap-5 flex mt-[-20px]">
        <div
          className="w-10 h-10 rounded-full flex items-center justify-center bg-[#38DA711A] cursor-pointer"
          onClick={mobilePrev}
        >
          <img src={iconImages.Prev} alt="Previous" />
        </div>
        <div
          className="w-10 h-10 rounded-full flex items-center justify-center bg-[#006D33] cursor-pointer"
          onClick={mobileNext}
        >
          <img src={iconImages.Next} alt="Next" />
        </div>
      </div>
    </div>
  );
};

export default Clients;
