import { iconImages } from "../utils/images";
import UpdatesItem from "./UpdatesItem";

const Updates = () => {
  return (
    <div className="flex flex-col lg:mt-20 md:mt-5 lg:gap-4">
      <div className="mx-auto flex flex-col gap-3 text-center md:w-[450px]">
        <h3 className="text-[#006D33] font-bold text-2xl md:text-[30px]">
          Our Latest Updates
        </h3>
        <p className="text-[#141F39] text-sm md:text-base">
          Stay connected with Ajeoba's mission and vision for agricultural
          advancement.
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 my-5">
        <UpdatesItem
          id={1}
          name={"John Doe"}
          date={"Oct 10th, 2023"}
          title="Agriculture made smarter with Ajeoba"
          description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
          icon={iconImages.Plant}
        />

        <UpdatesItem
          id={2}
          name={"John Doe"}
          date={"Oct 10th, 2023"}
          title="Agriculture made smarter with Ajeoba"
          description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
          icon={iconImages.Agric}
        />

        <UpdatesItem
          id={3}
          name={"John Doe"}
          date={"Oct 10th, 2023"}
          title="Agriculture made smarter with Ajeoba"
          description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
          icon={iconImages.Seedlings}
        />
      </div>

      <button className="px-5 py-3 md:px-12 md:py-4 cursor-pointer bg-[#006D33] hover:bg-[#016D41] text-white font-bold relative rounded-xl w-fit flex items-center gap-2 mx-auto text-sm md:text-base">
        <p> View More</p>
        <img src={iconImages.Arrow} alt="" className="" />
      </button>
    </div>
  );
};

export default Updates;
