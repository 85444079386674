import React, { useState } from "react";
import Nav from "../components/Nav";
import { iconImages } from "../utils/images";
import Footer from "../components/Footer";

const FAQs = [
  {
    id: "1",
    question: "What is Ajeoba?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Ultrices sed lacus quis viverra. Eu donec etiam aliquam semper blandit varius. Justo ante nisi amet adipiscing. Sed id blandit consectetur morbi morbi varius bibendum egestas orci. Quis.",
  },
  {
    id: "2",
    question: "How does Ajeoba works?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Ultrices sed lacus quis viverra. Eu donec etiam aliquam semper blandit varius. Justo ante nisi amet adipiscing. Sed id blandit consectetur morbi morbi varius bibendum egestas orci. Quis.",
  },
  {
    id: "3",
    question: "Who can use Ajeoba?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Ultrices sed lacus quis viverra. Eu donec etiam aliquam semper blandit varius. Justo ante nisi amet adipiscing. Sed id blandit consectetur morbi morbi varius bibendum egestas orci. Quis.",
  },
  {
    id: "4",
    question:
      "What type of agricultural products can  be bought and sold on Ajeoba?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Ultrices sed lacus quis viverra. Eu donec etiam aliquam semper blandit varius. Justo ante nisi amet adipiscing. Sed id blandit consectetur morbi morbi varius bibendum egestas orci. Quis.",
  },
  {
    id: "5",
    question: "Are there any fees associated with using Ajeoba?",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Ultrices sed lacus quis viverra. Eu donec etiam aliquam semper blandit varius. Justo ante nisi amet adipiscing. Sed id blandit consectetur morbi morbi varius bibendum egestas orci. Quis.",
  },
];

const Faq = () => {
  const [filter, setFilter] = useState("all");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  return (
    <>
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mb-10 mt-[100px] lg:mb-24 lg:mt-[130px]">
        <div className="flex flex-col gap-5">
          <p className="text-[#040405] font-bold text-[30px] md:text-[40px] lg:text-4xl">
            Help Center
          </p>

          <div className="flex items-center justify-around gap-2 overflow-x-scroll scrollbar-none">
            <div
              className={`py-2 px-5 ${
                filter === "all"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("all")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">All</p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Farming Association"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Farming Association")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Farming Association
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Input Supplier"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Input Supplier")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Input Supplier
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Aggregator"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Aggregator")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Aggregator
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Corporate Buyer"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Corporate Buyer")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Corporate Buyer
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Quality Assurance"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Quality Assurance")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Quality Assurance
              </p>
            </div>

            <div
              className={`py-2 px-5 ${
                filter === "Logistics"
                  ? "bg-[#006D33] text-white"
                  : "bg-white text-[#141F39]"
              } flex items-center  gap-2 w-fit rounded-md cursor-pointer shadow-md`}
              onClick={() => setFilter("Logistics")}
            >
              <p className="font-semibold text-[11px] whitespace-nowrap">
                Logistics
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            {FAQs.map((faq) => {
              return (
                <div
                  key={faq.id}
                  className={`${
                    isOpen && selectedId === faq.id
                      ? "bg-[#006D33]"
                      : "bg-[#F0F5EC]"
                  } p-5 md:p-7 rounded-lg flex items-start justify-between gap-5 md:gap-0`}
                >
                  <p
                    className={`basis-[5%] font-bold text-xl md:text-base lg:text-lg ${
                      isOpen && selectedId === faq.id
                        ? "text-white"
                        : "text-[#003C1C]"
                    }`}
                  >
                    {faq.id.length < 2 ? `0${faq.id}` : faq.id}
                  </p>

                  <div className="basis-[85%] flex flex-col gap-2">
                    <p
                      className={`${
                        isOpen && selectedId === faq.id
                          ? "text-white"
                          : "text-[#003C1C]"
                      } text-xs md:text-sm lg:text-base font-medium mt-1`}
                    >
                      {faq.question}
                    </p>
                    {isOpen && selectedId === faq.id && (
                      <p
                        className={`${
                          isOpen && selectedId === faq.id
                            ? "text-white"
                            : "text-[#003C1C]"
                        } text-[10px] md:text-xs lg:text-sm`}
                      >
                        {faq.answer}
                      </p>
                    )}
                  </div>

                  <div className="basis-[5%] flex">
                    {selectedId === faq.id ? (
                      <div
                        className="w-5 h-5 md:h-7 md:w-7 bg-white flex items-center justify-center rounded-full cursor-pointer ml-auto"
                        onClick={() => {
                          setIsOpen(false);
                          setSelectedId(null);
                        }}
                      >
                        <img src={iconImages.Times} alt="" className="w-10" />
                      </div>
                    ) : (
                      <div
                        className="w-5 h-5 md:h-7 md:w-7 bg-white flex items-center justify-center rounded-full cursor-pointer ml-auto"
                        onClick={() => {
                          setSelectedId(faq.id);
                          setIsOpen(true);
                        }}
                      >
                        <img
                          src={iconImages.Add}
                          alt=""
                          className="w-4 md:w-5"
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </>
  );
};

export default Faq;
