import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { iconImages } from "../utils/images";
import UpdatesItem from "../components/UpdatesItem";

const Update = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Nav />
      <div className="flex flex-col px-5 md:px-20 lg:px-40 mt-24">
        <div className="flex flex-col w-full relative">
          <div className="w-full h-full absolute top-0 bottom-0 right-0 z-[-1000]">
            <img src={iconImages.About} alt="" className="w-full h-full" />
          </div>
          <div className="flex flex-col items-center gap-5 lg:w-[55%] order-1 lg:order-2 mx-auto">
            <p className="text-[#141F39] font-bold text-[30px] md:text-[40px] lg:text-4xl">
              Our Updates
            </p>
            <p className="font-[inter] text-[#4F4F4F] font-[400]  text-xs md:text-sm text-center">
              Stay informed and inspired with Ajeoba's latest updates. Explore
              insightful blogs, breaking news, and industry highlights curated
              to empower your agricultural journey.
            </p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 my-5">
          <UpdatesItem
            id={1}
            name={"Onaivio"}
            date={"Oct 10th, 2023"}
            title="Organization of African Unity (O.A.U.) proclaimed 20th November as Africa Industrialization Day. "
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Map}
          />

          <UpdatesItem
            id={2}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Agric}
          />

          <UpdatesItem
            id={3}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Seedlings}
          />

          <UpdatesItem
            id={4}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Plant}
          />

          <UpdatesItem
            id={5}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Agric}
          />

          <UpdatesItem
            id={6}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Seedlings}
          />

          <UpdatesItem
            id={7}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Plant}
          />

          <UpdatesItem
            id={8}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Agric}
          />

          <UpdatesItem
            id={9}
            name={"John Doe"}
            date={"Oct 10th, 2023"}
            title="Agriculture made smarter with Ajeoba"
            description="Lorem ipsum dolor sit amet consectetur. Odio at in a duis feugiat in
            auctor ultricies. Id erat vive..."
            icon={iconImages.Seedlings}
          />
        </div>

        <button className="px-5 py-[10px] md:px-12 md:py-4 border border-[#006D33] w-fit flex items-center justify-center gap-2 mx-auto mb-10 rounded-lg focus:outline-none">
          <p className="text-[#006D33] font-semibold text-sm md:text-base">
            Load More
          </p>
          <img src={iconImages.MarketArrow} alt="" className="" />
        </button>
      </div>

      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default Update;
