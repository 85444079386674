import { iconImages } from "../utils/images";

const ClientItems = (props) => {
  const {  note, name, title, image } = props;

  return (
    <div className="bg-[#F7F9F4] p-10 md:p-3 lg:p-10 rounded-2xl flex flex-col gap-4 w-full md:w-1/2 sm:w-full">
      <div>
        <img src={iconImages.Quote} alt="" className="opacity-30 w-10 md:w-14" />
      </div>
      <p className="font-[inter] font-[300] lg:text-base md:text-left text-xs md:text-sm">
       {note}
      </p>
      <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-4">
        <div className="flex items-center gap-2 lg:order-1 order-2">
          <img src={image} alt="" className="w-10 lg:w-14" />
          <div className="font-[inter]">
            <p className="text-[#006D33] text-sm font-semibold">
              {name}
            </p>
            <p className="text-sm ">{title}</p>
          </div>
        </div>

        <div className="flex items-center gap-1 lg:order-2 order-1">
          <img src={iconImages.Star} alt="" />
          <img src={iconImages.Star} alt="" />
          <img src={iconImages.Star} alt="" />
          <img src={iconImages.Star} alt="" />
          <img src={iconImages.Star} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ClientItems;
