import React from "react";
import { iconImages } from "../utils/images";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full bg-[#090909] px-5 md:px-20 lg:px-40 py-10 text-white flex flex-col gap-10">
      <div className="flex md:flex-row flex-col items-start justify-between">
        <div className="flex flex-col gap-4 basis-[35%]">
          <div className="md:w-[120px] w-[90px]">
            <img src={iconImages.Logo} alt="" className="md:w-24 lg:w-full" />
          </div>

          <p className="text-xs md:text-sm">
            Ajeoba is an agriculture value-chain digital infrastructure provider
            focused on bridging various gaps identified in the agricultural
            value chain.
          </p>

          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2 text-xs md:text-sm">
              <img src={iconImages.Phone} alt="" />
              <p>+234 9070534510</p>
            </div>

            <div className="flex items-center gap-2 text-xs md:text-sm">
              <img src={iconImages.Email} alt="" />
              <p>admin@ajeoba.com</p>
            </div>

            <div className="flex items-center gap-2 text-xs md:text-sm">
              <img src={iconImages.Location} alt="" />
              <p>13 Okotie Eboh Street, Ikoyi, Lagos State.</p>
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-start justify-between basis-1/2 mt-5 md:mt-0">
          <div className="flex flex-col gap-1 md:gap-3 mb-5 md:mb-0">
            <h2 className="font-semibold lg:text-lg">Useful Links</h2>
            <Link to="/comingsoon">
              <p className="text-sm">Features</p>
            </Link>

            <Link to="/about">
              <p className="text-sm">About</p>
            </Link>

            <Link to="/terms-and-conditions">
              <p className="text-sm">Service</p>
            </Link>

            <Link to="/comingsoon">
              <p className="text-sm">Teams</p>
            </Link>
          </div>

          <div className="flex flex-col gap-1 md:gap-3 mb-5">
            <h2 className="font-semibold lg:text-lg">Help & Support</h2>
            <Link to="/faq">
              <p className="text-sm">FAQ</p>
            </Link>

            <Link to="/update">
              <p className="text-sm">Blog</p>
            </Link>

            <Link to="/contactus">
              <p className="text-sm">Contact Us</p>
            </Link>

            <Link to="/faq">
              <p className="text-sm">Support</p>
            </Link>

            <Link to="/privacy">
              <p className="text-sm">Privacy</p>
            </Link>
          </div>

          <div className="flex flex-col gap-1 md:gap-3">
            <h2 className="font-semibold lg:text-lg">Resources</h2>
            <Link to="/comingsoon">
              <p className="text-sm">Guides and resources</p>
            </Link>

            <Link to="/comingsoon">
              <p className="text-sm">Team</p>
            </Link>

            <Link to="/comingsoon">
              <p className="text-sm">Tools</p>
            </Link>

            <Link to="/faq">
              <p className="text-sm">Support</p>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:items-center justify-between">
        <p className="text-xs md:text-sm mb-2 md:mb-0">
          &copy; Copyright &copy; 2023 Ajeoba. All right reserved
        </p>

        <div className="flex items-center gap-5 md:gap-10">
          <img src={iconImages.Insta} alt="" className="" />
          <img src={iconImages.Twitter} alt="" className="" />
          <img src={iconImages.LinkedIn} alt="" className="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;

//w-full bg-[#090909] px-20 py-10 text-white
